
import { Component, Vue } from "vue-property-decorator";

@Component
export default class JoinusModal extends Vue {
  //methods

  closeModal(): void {
    this.$bvModal.hide("join-us-modal");
  }
}
