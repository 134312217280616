
import { Component, Vue } from "vue-property-decorator";
// import Navbar from "@/components/Utility/Navbar.vue";
// import Footer from "@/components/Utility/Footer.vue";
import JoinusTop from "@/components/Utility/JoinusTop.vue";
import JoinusModal from "@/components/JoinUs/JoinusModal.vue";
@Component({
  components: {
    // Navbar,
    // Footer,
    JoinusTop,
    JoinusModal,
  },
})
export default class JoinUs extends Vue {
  //data
  boo = false;
  //hook
  created() {
    this.boo = "__PRERENDER_INJECTED" in window;
  }
  mounted(): void {
    this.$bvModal.show("join-us-modal");
    setTimeout(() => {
      const modals = document.querySelectorAll("#join-us-modal___BV_modal_outer_");

      if (modals.length > 1) {
        for (let i = 1; i < modals.length; i++) {
          modals[i].remove();
        }
      }
    }, 300)
  }
}
